import Constants from '../constants';
import Qs from 'qs';
import {
    initialNetworkStatus,
    processLocalChange,
    processObjectRequest,
    processObjectResponse,
    processQueryRequest,
    processQueryResponse,
    processSaveObject,
    processSaveObjectResponse,
    processDeleteObjectResponse,
    processPatchResponse,
    processPatch,
    processPostObject,
    processPostObjectResponse,
    showPBxConfirmIntervalModal,
    hidePBxConfirmIntervalModal,
    showPbxModifyTrialModal,
    hidePbxModifyTrialModal,
    updatePbxResumeInterval,
    resetPbxResumeInterval,
} from '../../../../../helpers/reducer';
import { hasData } from '../../../../../components/history/helpers/stats';

//do not forget to NOT MUTATE any references before then are copied

const initialState = {
    initializingData: true,
    currentClientId: undefined,
    currentClientSessionId: undefined,
    currentSessionNotesEntryId: undefined,
    currentLessonPlanId: undefined,
    currentClientAppointments: {},
    currentMissingAppointments: {}, // Don't store this in indexDB because we want users to be online to do this.
    permissions: {
        edit: false,
        view: false,
    },
    prompts: {
        startSession: { show: false, requester: null },
        dataSheetSettings: { show: false, dataSheetId: null, requester: null },
        pbx: {
            EventRecordingAbcStructured: { modifyTrial: [] },
            EventRecordingAbcNarrative: { modifyTrial: [] },
            Duration: { modifyTrial: [] },
            MTS: { confirmInterval: [] },
            PIR: { confirmInterval: [] },
            PIRAbcStructured: { confirmInterval: [], modifyTrial: [] },
            PIRAbcNarrative: { confirmInterval: [], modifyTrial: [] },
            Interval: { confirmInterval: [], modifyTrial: [], resumeInterval: [] }, // This is used by IntervalModals where we group all 4 Interval data sheet modals
        },
        abx: {}
    },
    promptQueue: [],
    dataSheetStatesById: {},
    stats: {
        signDocument: {
            sessionNote: 0,
            BIP: 0,
        },
        signTechnicianDocument: {
            sessionNote: 0,
        },
    },
    entities: {
        ClientSessions: {
            byId: {},
            allIds: [],
            networkStatusById: {
                current: { ...initialNetworkStatus }
            }
        },
        ClientSessionsStats: {
            id: 'ClientId-ClientSessionId',
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        Trials: {
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        DataSheets: {
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        DataSheetsPTargets: {
            id: 'DataSheetId-PTargetId-SubTargetId',
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        DataSheetsSequences: {
            id: 'ClientId-PSdRId-PProblemBxId-MaintenancePSdRId',
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        Clients: {
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        Accounts: {
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        OAccounts: {
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        ClientsOAccounts: {
            id: 'ClientId-OAccountId-LessonPlanId-type',
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        PLessons: {
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        DevDomains: {
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        PSdRs: {
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        PSdRsDataSheets: {
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        PSdRsGoals: {
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        MaintenancePSdRs: {
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        PTargets: {
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        PProblemBxes: {
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        PProblemBxesHistory: {
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        PProblemBxesDataSheets: {
            id: 'PProblemBxId-DataSheetId',
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        PProblemBxesDataSheetTypes: {
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        PPBxesGoals: {
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        PPBxesGoalsLessons: {
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        Lessons: {
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        PProblemBxesLessons: {
            id: 'PProblemBxId-LessonId-type',
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        PAntecedentInterventions: {
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        PConsequenceInterventions: {
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        Notes: {
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        SignificantEvents: {
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        DeptPositions: {
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        OAccountsOAdminGroups: {
            id: 'OAdminGroupId-OAccountId',
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        ClientActivities: {
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        FundingSourcesGroups: {
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        SessionNotesTypes: {
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        ServiceTypes: {
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        CPTCodes: {
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        SessionNotes: {
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        SessionNotesSignatures: {
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        SessionNotesMatchingRules: {
            id: 'FundingSourceId-ServiceTypeId-CPTCodeId',  // these three are unique so we don't need SessionNoteId
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        FundingSources: {
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        Appointments: {
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        SessionNotesEntries: {
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        SessionNotesEntriesSignatures: {
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        ClientsAuthorizations: {
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        ClientsLocations: {
            id: 'ClientId-LocationId',  // these three are unique so we don't need SessionNoteId
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        GeoLocations: {
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        Users: {
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        Signatures: {
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        LessonPlans: {
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        SessionNotesLocations: {
            byId: {},
            allIds: [],
            networkStatusById: {}
        },
        PPBxesGoalsLessonsDataSheets: {
            id: 'PProblemBxId-PPBxesGoalId-PPBxesGoalsLessonId-DataSheetId',
            byId: {},
            allIds: [],
            networkStatusById: {},
        },
        PPBxesGoalsLessonsDataSheetTypes: {
            byId: {},
            allIds: [],
            networkStatusById: {},
        },
        SignerTypes: {
            byId: {},
            allIds: [],
            networkStatusById: {}
        },        
    },
    collections: {
        PLessons: {
            idsByQuery: {},
            itemsByQuery: {},
            pagesByQuery: {},
            networkStatusByQuery: {}
        },
        PProblemBxes: {
            idsByQuery: {},
            itemsByQuery: {},
            pagesByQuery: {},
            networkStatusByQuery: {}
        },
        DataSheets: {
            idsByQuery: {},
            itemsByQuery: {},
            pagesByQuery: {},
            networkStatusByQuery: {}
        },
        DataSheetsSequences: {
            idsByQuery: {},
            itemsByQuery: {},
            pagesByQuery: {},
            networkStatusByQuery: {}
        },
        Clients: {
            idsByQuery: {},
            itemsByQuery: {},
            pagesByQuery: {},
            networkStatusByQuery: {}
        },
        ClientSessions: {
            idsByQuery: {},
            itemsByQuery: {},
            pagesByQuery: {},
            networkStatusByQuery: {}
        },
        ClientSessionsStats: {
            idsByQuery: {},
            itemsByQuery: {},
            pagesByQuery: {},
            networkStatusByQuery: {}
        },
        ClientsOAccounts: {
            idsByQuery: [],
            itemsByQuery: {},
            pagesByQuery: {},
            networkStatusByQuery: {}
        },
        Notes: {
            idsByQuery: {},
            itemsByQuery: {},
            pagesByQuery: {},
            networkStatusByQuery: {}
        },
        ClientActivities: {
            idsByQuery: {},
            itemsByQuery: {},
            pagesByQuery: {},
            networkStatusByQuery: {}
        },
        SessionNotes: {
            idsByQuery: {},
            itemsByQuery: {},
            pagesByQuery: {},
            networkStatusByQuery: {}
        },        
        Appointments: {
            idsByQuery: {},
            itemsByQuery: {},
            pagesByQuery: {},
            networkStatusByQuery: {}
        },
        SessionNotesEntries: {
            idsByQuery: {},
            itemsByQuery: {},
            pagesByQuery: {},
            networkStatusByQuery: {}
        },
        SignificantEvents: {
            idsByQuery: {},
            itemsByQuery: {},
            pagesByQuery: {},
            networkStatusByQuery: {}
        },
        LessonPlans: {
            idsByQuery: {},
            itemsByQuery: {},
            pagesByQuery: {},
            networkStatusByQuery: {}
        },
    }
};


const reducer = function (state = initialState, action) {

    if (action.type === Constants.RESET_ALL_DETAILS) {
        return { ...initialState };
    }

    if (action.type === Constants.RESTORE_FROM_INDEXED_DB) {
        return { ...action.saved_state };
    }

    if (action.type === Constants.SHOW_START_SESSION) {
        state = {
            ...state,
            prompts: {
                ...state.prompts,
                startSession: { show: true, requester: action.requester }
            }
        };
        return state;
    }

    if (action.type === Constants.HIDE_START_SESSION) {
        state = {
            ...state,
            prompts: {
                ...state.prompts,
                startSession: { ...state.prompts.startSession, show: false }
            }
        };
        return state;
    }

    if (action.type === Constants.SHOW_DATA_SHEET_SETTINGS) {
        state = {
            ...state,
            prompts: {
                ...state.prompts,
                dataSheetSettings: { show: true, requester: action.requester, dataSheetId: action.dataSheetId }
            }
        };
        return state;
    }

    if (action.type === Constants.HIDE_DATA_SHEET_SETTINGS) {
        state = {
            ...state,
            prompts: {
                ...state.prompts,
                dataSheetSettings: { ...state.prompts.dataSheetSettings, show: false }
            }
        };
        return state;
    }

    if (action.type === Constants.UPDATE_CLIENT_SESSION) {
        const clientSession = action.clientSession;
        state = processLocalChange(state, 'ClientSession', clientSession, !!action.remove);
        if (action.setCurrent && !clientSession.complete) {
            state = {
                ...state,
                currentClientSessionId: clientSession.id,
            };
        } else if (clientSession.id === state.currentClientSessionId && clientSession.complete) {
            state = {
                ...state,
                currentClientSessionId: null
            };
        }

        return state;
    }

    if (action.type === Constants.GET_CURRENT_SESSION) {
        return processObjectRequest(action, state, 'ClientSessions');
    }

    if (action.type === Constants.GET_CURRENT_SESSION_RESPONSE) {
        const id = action.request.url.match(/[^/]*$/)[0];
        state = processObjectResponse(action, state, 'ClientSession', 'ClientSessions');
        const networkUpdates = state.entities.ClientSessions.networkStatusById[id];

        //pretty sure id will be 'current'
        if (id === 'current' && !networkUpdates.err && !networkUpdates.error) {
            // there is a race condition when we end the session and fetch the current session, the database isn't updated, resulting in old clientSessionId
            // so we need to check for clientSession
            const clientSession = state.entities.ClientSessions.byId[action.response.id];
            if (!clientSession || clientSession.complete) return state;

            //id could be 'current', so we need to do it for this id too
            state = {
                ...state,
                currentClientSessionId: action.response.id,
                entities: {
                    ...state.entities,
                    ClientSessions: {
                        ...state.entities.ClientSessions,
                        networkStatusById: {
                            ...state.entities.ClientSessions.networkStatusById,
                            [action.response.id]: networkUpdates
                        }
                    }
                }
            };
        }

        return state;
    }

    if (action.type === Constants.SAVE_CLIENT_SESSION) {
        state = processObjectRequest(action, state, 'ClientSessions');
        const id = action.request.url.match(/[^/]*$/)[0];
        //id could be the same as the at  'current', so we need to do it for this id too
        if (state.currentClientSessionId === id) {
            state = {
                ...state,
                entities: {
                    ...state.entities,
                    ClientSessions: {
                        ...state.entities.ClientSessions,
                        networkStatusById: {
                            ...state.entities.ClientSessions.networkStatusById,
                            current: { ...state.entities.ClientSessions.networkStatusById[id] }
                        }
                    }
                }
            };
        }

        return state;
    }

    if (action.type === Constants.SAVE_CLIENT_SESSION_RESPONSE) {
        const id = action.request.url.match(/[^/]*$/)[0];
        state = processObjectResponse(action, state, 'ClientSession', 'ClientSessions');
        const networkUpdates = state.entities.ClientSessions.networkStatusById[id];
        if (state.currentClientSessionId === id) {
            //id could be the same as the at  'current', so we need to do it for this id too
            state = {
                ...state,
                entities: {
                    ...state.entities,
                    ClientSessions: {
                        ...state.entities.ClientSessions,
                        networkStatusById: {
                            ...state.entities.ClientSessions.networkStatusById,
                            current: networkUpdates
                        }
                    }
                }
            };
        }

        return state;
    }
    if (action.type === Constants.GET_LATEST_SESSION) {
        return processObjectRequest(action, state, 'ClientSessions');
    }

    if (action.type === Constants.GET_LATEST_SESSION_RESPONSE) {
        return processObjectResponse(action, state, 'ClientSession', 'ClientSessions');
    }


    if (action.type === Constants.INITIAL_DOWNLOAD_COMPLETE) {
        return {
            ...state,
            currentClientId: action.id,
            currentLessonPlanId: action.lessonPlanId,
            initializingData: false,
        };
    }

    if (action.type === Constants.GET_PSDR) {
        return processObjectRequest(action, state, 'PSdRs');
    }

    //this should only happen once
    if (action.type === Constants.GET_PSDR_RESPONSE) {
        return processObjectResponse(action, state, 'PSdR', 'PSdRs');
    }

    if (action.type === Constants.GET_MAINTENANCE_PSDR) {
        return processObjectRequest(action, state, 'MaintenancePSdRs');
    }

    //this should only happen once
    if (action.type === Constants.GET_MAINTENANCE_PSDR_RESPONSE) {
        return processObjectResponse(action, state, 'MaintenancePSdR', 'MaintenancePSdRs');
    }

    if (action.type === Constants.GET_PLESSON) {
        return processObjectRequest(action, state, 'PLessons');
    }

    //this should only happen once
    if (action.type === Constants.GET_PLESSON_RESPONSE) {

        return processObjectResponse(action, state, 'PLesson', 'PLessons');
    }

    if (action.type === Constants.GET_PPROBLEM_BX) {
        return processObjectRequest(action, state, 'PProblemBxes');
    }

    if (action.type === Constants.GET_PPROBLEM_BX_RESPONSE) {
        return processObjectResponse(action, state, 'PProblemBx', 'PProblemBxes');
    }

    if (action.type === Constants.GET_ACCOUNT_SIGNIFICANT_EVENTS) {
        return processQueryRequest(action, state, 'SignificantEvents');
    }

    if (action.type === Constants.GET_ACCOUNT_SIGNIFICANT_EVENTS_RESPONSE) {
        return processQueryResponse(action, state, 'SignificantEvent', 'SignificantEvents');
    }

    if (action.type === Constants.GET_ADMIN_SIGNIFICANT_EVENTS) {
        return processQueryRequest(action, state, 'SignificantEvents');
    }

    if (action.type === Constants.GET_ADMIN_SIGNIFICANT_EVENTS_RESPONSE) {
        return processQueryResponse(action, state, 'SignificantEvent', 'SignificantEvents');
    }

    if (action.type === Constants.GET_DATA_SHEETS_BY_CLIENT) {
        return processQueryRequest(action, state, 'DataSheets');
    }

    if (action.type === Constants.GET_DATA_SHEETS_BY_CLIENT_RESPONSE) {
        return processQueryResponse(action, state, 'DataSheet', 'DataSheets');
    }

    if (action.type === Constants.GET_ALL_CLIENTS) {
        return processQueryRequest(action, state, 'Clients');
    }

    if (action.type === Constants.GET_ALL_CLIENTS_RESPONSE) {
        return processQueryResponse(action, state, 'Client', 'Clients');
    }

    if (action.type === Constants.GET_LESSON_PLANS_BY_CLIENT) {
        return processQueryRequest(action, state, 'LessonPlans');
    }

    if (action.type === Constants.GET_LESSON_PLANS_BY_CLIENT_RESPONSE) {

        return processQueryResponse(action, state, 'LessonPlan', 'LessonPlans');
    }   

    if (action.type === Constants.GET_CLIENT_SESSION_BY_CLIENT) {
        return processObjectRequest(action, state, 'ClientSessions');
    }

    if (action.type === Constants.GET_CLIENT_SESSION_BY_CLIENT_RESPONSE) {
        return processObjectResponse(action, state, 'ClientSession', 'ClientSessions');
    }

    if (action.type === Constants.GET_ALL_CLIENT_SESSIONS_BY_CLIENT) {
        return processQueryRequest(action, state, 'ClientSessions');
    }

    if (action.type === Constants.GET_ALL_CLIENT_SESSIONS_BY_CLIENT_RESPONSE) {
        return processQueryResponse(action, state, 'ClientSession', 'ClientSessions');
    }

    if (action.type === Constants.GET_ALL_CLIENT_SESSIONS_STATS_BY_CLIENT) {
        return processQueryRequest(action, state, 'ClientSessionsStats');
    }

    if (action.type === Constants.GET_ALL_CLIENT_SESSIONS_STATS_BY_CLIENT_RESPONSE) {

        return processQueryResponse(action, state, 'ClientSessionsStat',
            'ClientSessionsStats');
    }

    if (action.type === Constants.GET_CLIENT_DETAILS) {
        return processObjectRequest(action, state, 'Clients');
    }

    //this should only happen once
    if (action.type === Constants.GET_CLIENT_DETAILS_RESPONSE) {
        return processObjectResponse(action, state, 'Client', 'Clients');
    }

    if (action.type === Constants.UPDATE_DATA_SHEET) {
        return processLocalChange(state, 'DataSheet', action.dataSheet, !!action.remove);
    }

    if (action.type === Constants.UPDATE_DATA_SHEETS_PTARGET) {
        return processLocalChange(state, 'DataSheetsPTarget', action.dataSheetsPTarget, !!action.remove);
    }
    if (action.type === Constants.UPDATE_PPROBLEM_BXES_DATA_SHEET) {
        return processLocalChange(state, 'PProblemBxesDataSheet', action.pProblemBxesDataSheet, !!action.remove);
    }
    if (action.type === Constants.SAVE_DATA_SHEET) {
        return processSaveObject(action, state, 'DataSheets');
    }

    if (action.type === Constants.SAVE_DATA_SHEET_RESPONSE) {
        return processSaveObjectResponse(action, state, 'DataSheet');
    }

    if (action.type === Constants.DELETE_DATA_SHEET) {
        return processObjectRequest(action, state, 'DataSheets');
    }

    if (action.type === Constants.DELETE_DATA_SHEET_RESPONSE) {
        return processDeleteObjectResponse(action, state, 'DataSheets');
    }

    if (action.type === Constants.PATCH_PTARGETS_BY_PLESSON) {

        return processPatch(action, 'pTarget', state, 'PTargets');
    }

    if (action.type === Constants.PATCH_PTARGETS_BY_PLESSON_RESPONSE) {

        return processPatchResponse(action, 'pTarget', state, 'PTarget');
    }

    //creating a join between a data sheet and a pproblembx
    if (action.type === Constants.POST_PPROBLEM_BXES_DATA_SHEET) {
        return processPostObject(action, state, 'PProblemBxesDataSheet');
    }

    if (action.type === Constants.POST_PPROBLEM_BXES_DATA_SHEET_RESPONSE) {
        return processPostObjectResponse(action, state, 'PProblemBxesDataSheet');
    }

    if (action.type === Constants.ADD_DATA_SHEET) {

        state = processPostObject(action, state, 'DataSheet');
        //this is a bit unusual because payload has the problembx in it to.
        //so we fake it that this was sent up as post as well
        const DataSheetId = action.request.data.id;
        const requestData = action.request.data;
        const data = { DataSheetId };
        if (requestData.pProblemBxIds) {
            data.PProblemBxId = requestData.pProblemBxIds[0];
            return processPostObject({ request: { data } }, state, 'PProblemBxesDataSheet');
        }
        return state;
    }

    if (action.type === Constants.ADD_DATA_SHEET_RESPONSE) {

        state = processPostObjectResponse(action, state, 'DataSheet');
        //this is a bit unusual because payload has the problembx in it to.
        //so we fake it that this was sent up as post as well
        //we use processSaveObjectResponse because it does what want it to
        //which is end the faking of the Join Object post.
        const DataSheetId = action.request.data.id;
        const requestData = action.request.data;
        const data = { DataSheetId };
        if (requestData.pProblemBxIds) {
            data.PProblemBxId = requestData.pProblemBxIds[0];
            return processSaveObjectResponse({ request: { data } }, state, 'PProblemBxesDataSheet')
        }
        return state;

    }

    if (action.type === Constants.GET_ALL_PLESSONS_BY_CLIENT) {
        return processQueryRequest(action, state, 'PLessons');
    }

    if (action.type === Constants.GET_ALL_PLESSONS_BY_CLIENT_RESPONSE) {
        return processQueryResponse(action, state, 'PLesson', 'PLessons');
    }

    if (action.type === Constants.GET_ALL_PPROBLEM_BXES_BY_CLIENT) {
        return processQueryRequest(action, state, 'PProblemBxes');
    }

    if (action.type === Constants.GET_ALL_PPROBLEM_BXES_BY_CLIENT_RESPONSE) {
        return processQueryResponse(action, state, 'PProblemBx', 'PProblemBxes');
    }

    if (action.type === Constants.GET_ALL_DATA_SHEETS_BY_CLIENT) {
        return processQueryRequest(action, state, 'DataSheets');
    }

    if (action.type === Constants.GET_ALL_DATA_SHEETS_BY_CLIENT_RESPONSE) {
        return processQueryResponse(action, state, 'DataSheet', 'DataSheets');
    }

    if (action.type === Constants.GET_ALL_OACCOUNTS_BY_CLIENT) {
        return processQueryRequest(action, state, 'ClientsOAccounts');
    }

    if (action.type === Constants.GET_ALL_OACCOUNTS_BY_CLIENT_RESPONSE) {
        const newState = processQueryResponse(action, state, 'ClientsOAccount', 'ClientsOAccounts');
        return newState;
    }

    if (action.type === Constants.UPDATE_TRIAL) {
        return processLocalChange(state, 'Trial', action.trial, !!action.remove);
    }

    if (action.type === Constants.SAVE_TRIAL) {
        return processSaveObject(action, state, 'Trials');
    }

    if (action.type === Constants.SAVE_TRIAL_RESPONSE) {
        return processSaveObjectResponse(action, state, 'Trial');
        /**
         * Note: If the app crashes after completing data sheet and moving to session notes entry,
         * then we might need to review to see if we need to change from processSaveObjectResponse to processObjectResponse or not
         * because processSaveObjectResponse doesn't process the server payload to update the trial while processObjectResponse does
         */
        // return processObjectResponse(action, state, 'Trial', 'Trials');
    }

    if (action.type === Constants.DELETE_TRIAL) {
        return processObjectRequest(action, state, 'Trials');
    }

    if (action.type === Constants.DELETE_TRIAL_RESPONSE) {
        //this doesn't really cover when things go wrong
        return processDeleteObjectResponse(action, state, 'Trials');
    }

    if (action.type === Constants.UPDATE_PTARGET) {
        return processLocalChange(state, 'PTarget', action.pTarget, !!action.remove);
    }

    if (action.type === Constants.SAVE_PTARGET) {
        return processSaveObject(action, state, 'PTargets');
    }

    if (action.type === Constants.SAVE_PTARGET_RESPONSE) {
        return processSaveObjectResponse(action, state, 'PTarget');
    }

    if (action.type === Constants.DELETE_PTARGET) {
        return processObjectRequest(action, state, 'PTargets');
    }

    if (action.type === Constants.DELETE_PTARGET_RESPONSE) {
        //this doesn't really cover when things go wrong
        return processDeleteObjectResponse(action, state, 'PTargets');
    }

    if (action.type === Constants.UPDATE_DATA_SHEET_STATE) {
        state = {
            ...state,
            dataSheetStatesById: {
                ...state.dataSheetStatesById,
                [action.id]: { ...action.state }
            }
        };
        return state;
    }

    if (action.type === Constants.SHOW_MTS_CONFIRM_INTERVAL_MODAL) {
        return showPBxConfirmIntervalModal('MTS', action, state);
    }

    if (action.type === Constants.HIDE_MTS_CONFIRM_INTERVAL_MODAL) {
        return hidePBxConfirmIntervalModal('MTS', action, state);
    }

    if (action.type === Constants.SHOW_PIR_CONFIRM_INTERVAL_MODAL) {
        return showPBxConfirmIntervalModal('PIR', action, state);
    }

    if (action.type === Constants.HIDE_PIR_CONFIRM_INTERVAL_MODAL) {
        return hidePBxConfirmIntervalModal('PIR', action, state);
    }

    if (action.type === Constants.SHOW_PIR_ABC_STRUCTURED_CONFIRM_INTERVAL_MODAL) {
        return showPBxConfirmIntervalModal('PIRAbcStructured', action, state);
    }

    if (action.type === Constants.HIDE_PIR_ABC_STRUCTURED_CONFIRM_INTERVAL_MODAL) {
        return hidePBxConfirmIntervalModal('PIRAbcStructured', action, state);
    }

    if (action.type === Constants.SHOW_PIR_ABC_NARRATIVE_CONFIRM_INTERVAL_MODAL) {
        return showPBxConfirmIntervalModal('PIRAbcNarrative', action, state);
    }

    if (action.type === Constants.HIDE_PIR_ABC_NARRATIVE_CONFIRM_INTERVAL_MODAL) {
        return hidePBxConfirmIntervalModal('PIRAbcNarrative', action, state);
    }

    if (action.type === Constants.SHOW_CONFIRM_INTERVAL_MODAL) {
        return showPBxConfirmIntervalModal('Interval', action, state);
    }

    if (action.type === Constants.HIDE_CONFIRM_INTERVAL_MODAL) {
        return hidePBxConfirmIntervalModal('Interval', action, state);
    }

    if (action.type === Constants.UPDATE_RESUME_INTERVAL) {
        return updatePbxResumeInterval('Interval', action, state);
    }

    if (action.type === Constants.RESET_RESUME_INTERVAL) {
        return resetPbxResumeInterval('Interval', action, state);
    }

    if (action.type === Constants.SHOW_EVENT_RECORDING_ABC_STRUCTURED_MODIFY_TRIAL_MODAL) {
        return showPbxModifyTrialModal('EventRecordingAbcStructured', action, state);
    }

    if (action.type === Constants.HIDE_EVENT_RECORDING_ABC_STRUCTURED_MODIFY_TRIAL_MODAL) {
        return hidePbxModifyTrialModal('EventRecordingAbcStructured', action, state);
    }

    if (action.type === Constants.SHOW_EVENT_RECORDING_ABC_NARRATIVE_MODIFY_TRIAL_MODAL) {
        return showPbxModifyTrialModal('EventRecordingAbcNarrative', action, state);
    }

    if (action.type === Constants.HIDE_EVENT_RECORDING_ABC_NARRATIVE_MODIFY_TRIAL_MODAL) {
        return hidePbxModifyTrialModal('EventRecordingAbcNarrative', action, state);
    }

    if (action.type === Constants.SHOW_PIR_ABC_STRUCTURED_MODIFY_TRIAL_MODAL) {
        return showPbxModifyTrialModal('PIRAbcStructured', action, state);
    }

    if (action.type === Constants.HIDE_PIR_ABC_STRUCTURED_MODIFY_TRIAL_MODAL) {
        return hidePbxModifyTrialModal('PIRAbcStructured', action, state);
    }

    if (action.type === Constants.SHOW_PIR_ABC_NARRATIVE_MODIFY_TRIAL_MODAL) {
        return showPbxModifyTrialModal('PIRAbcNarrative', action, state);
    }

    if (action.type === Constants.HIDE_PIR_ABC_NARRATIVE_MODIFY_TRIAL_MODAL) {
        return hidePbxModifyTrialModal('PIRAbcNarrative', action, state);
    }

    if (action.type === Constants.SHOW_DURATION_MODIFY_TRIAL_MODAL) {
        return showPbxModifyTrialModal('Duration', action, state);
    }

    if (action.type === Constants.HIDE_DURATION_MODIFY_TRIAL_MODAL) {
        return hidePbxModifyTrialModal('Duration', action, state);
    }

    if (action.type === Constants.GET_ALL_NOTES_BY_CLIENT) {
        return processQueryRequest(action, state, 'Notes');
    }

    if (action.type === Constants.GET_ALL_NOTES_BY_CLIENT_RESPONSE) {
        return processQueryResponse(action, state, 'Note', 'Notes');
    }

    if (action.type === Constants.UPDATE_NOTE) {
        return processLocalChange(state, 'Note', action.note, !!action.remove);
    }

    if (action.type === Constants.POST_NOTE) {
        return processPostObject(action, state, 'Note');
    }

    if (action.type === Constants.POST_NOTE_RESPONSE) {
        state = processPostObjectResponse(action, state, 'Note');
        return state;
    }

    if (action.type === Constants.SAVE_NOTE) {
        return processSaveObject(action, state, 'Notes');
    }

    if (action.type === Constants.SAVE_NOTE_RESPONSE) {
        return processPostObjectResponse(action, state, 'Note');
    }

    if (action.type === Constants.ACKNOWLEDGE_NOTE) {
        return processPostObject(action, state, 'Note');
    }

    if (action.type === Constants.ACKNOWLEDGE_NOTE_RESPONSE) {
        return processPostObjectResponse(action, state, 'Note');
    }

    if (action.type === Constants.UPDATE_CLIENTS_OACCOUNT) {
        return processLocalChange(state, 'ClientsOAccount', action.clientsOAccount, !!action.remove);
    }

    if (action.type === Constants.GET_DATASHEETS_SEQUENCE) {
        return processQueryRequest(action, state, 'DataSheetsSequences');
    }

    if (action.type === Constants.GET_DATASHEETS_SEQUENCES_RESPONSE) {
        return processQueryResponse(action, state, 'DataSheetsSequence', 'DataSheetsSequences');
    }

    if (action.type === Constants.UPDATE_DATASHEETS_SEQUENCE) {
        // if dataSheetsSequence existed using ClientId-PSdRId-PProblemBxId then update DataSheetId
        // otherwise create an entry for it
        const dataSheet = action.dataSheet;
        if (!dataSheet.complete || !hasData(dataSheet)) return state;    // only do this when we complete the data sheet and if it has data

        let dataSheetSequence = null;
        const dataSheetsSequenceById = { ...state.entities.DataSheetsSequences.byId };

        if (dataSheet.PSdRId && !dataSheet.MaintenancePSdRId) {
            const pProblemBxId = '';  // empty holder
            const maintenancePSdRId = ''; // empty holder
            const dataSheetsSequenceId = `${dataSheet.ClientId}-${dataSheet.PSdRId}-${pProblemBxId}-${maintenancePSdRId}`;
            dataSheetSequence = dataSheetsSequenceById[dataSheetsSequenceId];
            if (!dataSheetSequence) {
                dataSheetSequence = {
                    ClientId: dataSheet.ClientId,
                    OrganizationId: dataSheet.OrganizationId,
                    PLessonid: dataSheet.PLessonId,
                    PSdRId: dataSheet.PSdRId,
                    MaintenancePSdRId: null,
                    PProblemBxId: null,
                    DataSheetId: dataSheet.id
                }

                dataSheetsSequenceById[dataSheetsSequenceId] = dataSheetSequence;
            }
            else {
                dataSheetSequence.DataSheetId = dataSheet.id;
            }
        }
        else if (dataSheet.MaintenancePSdRId) {
            const pProblemBxId = '';  // empty holder            
            const dataSheetsSequenceId = `${dataSheet.ClientId}-${dataSheet.PSdRId}-${pProblemBxId}-${dataSheet.MaintenancePSdRId}`;
            dataSheetSequence = dataSheetsSequenceById[dataSheetsSequenceId];
            if (!dataSheetSequence) {
                dataSheetSequence = {
                    ClientId: dataSheet.ClientId,
                    OrganizationId: dataSheet.OrganizationId,
                    PLessonid: dataSheet.PLessonId,
                    PSdRId: dataSheet.PSdRId,
                    MaintenancePSdRId: dataSheet.MaintenancePSdRId,
                    PProblemBxId: null,
                    DataSheetId: dataSheet.id
                }

                dataSheetsSequenceById[dataSheetsSequenceId] = dataSheetSequence;
            }
            else {
                dataSheetSequence.DataSheetId = dataSheet.id;
            }
        }
        else {
            dataSheet.PProblemBxes.forEach(pBxId => {
                const pSdRId = '';  // empty holder
                const maintenancePSdRId = ''; // empty holder
                const dataSheetsSequenceId = `${dataSheet.ClientId}-${pSdRId}-${pBxId}-${maintenancePSdRId}`;
                dataSheetSequence = dataSheetsSequenceById[dataSheetsSequenceId];
                if (!dataSheetSequence) {
                    dataSheetSequence = {
                        ClientId: dataSheet.ClientId,
                        OrganizationId: dataSheet.OrganizationId,
                        PLessonid: null,
                        PSdRId: null,
                        MaintenancePSdRId: null,
                        PProblemBxId: pBxId,
                        DataSheetId: dataSheet.id
                    }
                    dataSheetsSequenceById[dataSheetsSequenceId] = dataSheetSequence;
                }
                else {
                    dataSheetSequence.DataSheetId = dataSheet.id;
                }
            });

        }

        return {
            ...state,
            entities: {
                ...state.entities,
                DataSheetsSequences: {
                    ...state.entities.DataSheetsSequences,
                    byId: dataSheetsSequenceById
                }
            }
        }
    }

    if (action.type === Constants.GET_ALL_CLIENT_ACTIVITY_BY_CLIENT) {
        return processQueryRequest(action, state, 'ClientActivities');
    }

    if (action.type === Constants.GET_ALL_CLIENT_ACTIVITY_BY_CLIENT_RESPONSE) {
        return processQueryResponse(action, state, 'ClientActivity', 'ClientActivities');
    }

    if (action.type === Constants.UPDATE_CLIENTS_OACCOUNT_PERMISSIONS) {
        const clientsOAccounts = action.clientsOAccounts;

        let view = false;  // need to be Therapist or Caregiver
        let edit = false;  // need to be Therapist

        clientsOAccounts.forEach(clientsOAccount => {
            if (clientsOAccount.type === 'Therapist') {
                view = true;
                edit = true;
            }
            else if (clientsOAccount.type === 'Caregiver') {
                view = true;
            }
        });

        const permissions = { view, edit };

        return {
            ...state,
            permissions
        };
    }

    if (action.type === Constants.GET_SESSION_NOTES_BY_QUERY) {
        return processQueryRequest(action, state, 'SessionNotes');
    }

    if (action.type === Constants.GET_SESSION_NOTES_BY_QUERY_RESPONSE) {
        const id = action.request.url.match(/[^/]*$/)[0];
        const query = Qs.stringify(action.request.query);
        return processQueryResponse(action, state, 'SessionNote', 'SessionNotes');
    }

    if (action.type === Constants.UPDATE_SESSION_NOTES) {
        const sessionNotes = action.sessionNotes;
        state = processLocalChange(state, 'SessionNote', sessionNotes, !!action.remove);

        return state;
    }

    if (action.type === Constants.GET_APPOINTMENTS_BY_QUERY) {
        return processQueryRequest(action, state, 'Appointments');
    }

    if (action.type === Constants.GET_APPOINTMENTS_BY_QUERY_RESPONSE) {

        const id = action.request.url.match(/[^/]*$/)[0];
        const query = Qs.stringify(action.request.query);
        return processQueryResponse(action, state, 'Appointment', 'Appointments');
    }

    if (action.type === Constants.UPDATE_CURRENT_CLIENT_APPOINTMENTS) {
        return {
            ...state,
            currentClientAppointments: {
                ...action.response,
                data: action.response.data.map(item => item.id),
            },
        }
    }

    if (action.type === Constants.UPDATE_SESSION_NOTES_ENTRY) {
        const sessionNotesEntry = action.sessionNotesEntry;
        state = processLocalChange(state, 'SessionNotesEntry', sessionNotesEntry, !!action.remove);
        if (action.setCurrent && !sessionNotesEntry.completedAt && !sessionNotesEntry.deletedAt) {
            state = {
                ...state,
                currentSessionNotesEntryId: sessionNotesEntry.id,
            };
        } else if (sessionNotesEntry.id === state.currentSessionNotesEntryId && (sessionNotesEntry.completedAt || sessionNotesEntry.deletedAt || !!action.remove)) {
            state = {
                ...state,
                currentSessionNotesEntryId: null
            };
        }
        return state;
    }

    if (action.type === Constants.UPDATE_SESSION_NOTES_ENTRIES_SIGNATURE) {
        return processLocalChange(state, 'SessionNotesEntriesSignature', action.sessionNotesEntriesSignature, !!action.remove);
    }

    if (action.type === Constants.SAVE_SESSION_NOTES_ENTRY) {
        return processSaveObject(action, state, 'SessionNotesEntries');
    }

    if (action.type === Constants.SAVE_SESSION_NOTES_ENTRY_RESPONSE) {
        return processPostObjectResponse(action, state, 'SessionNotesEntry');
    }

    if (action.type === Constants.DELETE_SESSION_NOTES_ENTRY) {
        return processObjectRequest(action, state, 'SessionNotesEntries');
    }

    if (action.type === Constants.DELETE_SESSION_NOTES_ENTRY_RESPONSE) {
        return processDeleteObjectResponse(action, state, 'SessionNotesEntries');
    }

    if (action.type === Constants.GET_SESSION_NOTES_ENTRY_BY_SESSION) {
        return processQueryRequest(action, state, 'SessionNotesEntries');
    }

    if (action.type === Constants.GET_SESSION_NOTES_ENTRY_BY_SESSION_RESPONSE) {
        const id = action.request.url.match(/[^/]*$/)[0];
        const query = Qs.stringify(action.request.query);
        state = processQueryResponse(action, state, 'SessionNotesEntry', 'SessionNotesEntries');

        const networkUpdates = state.collections.SessionNotesEntries.networkStatusByQuery[query]

        //pretty sure id will be 'session-notes-entries'
        if (id === 'session-notes-entries' && !networkUpdates.err && !networkUpdates.error) {
            let currentSessionNotesEntryId = undefined;
            action.response.data.forEach(item => {
                if (!item.deletedAt) {
                    currentSessionNotesEntryId = item.id;
                }
            })

            if (currentSessionNotesEntryId) {
                //id could be 'current', so we need to do it for this id too
                state = {
                    ...state,
                    currentSessionNotesEntryId,
                    entities: {
                        ...state.entities,
                        SessionNotesEntries: {
                            ...state.entities.SessionNotesEntries,
                            networkStatusById: {
                                ...state.entities.SessionNotesEntries.networkStatusById,
                                [currentSessionNotesEntryId]: networkUpdates
                            }
                        }
                    }
                };
            }

        }

        return state;
    }

    if (action.type === Constants.GET_SESSION_NOTES_ENTRY_BY_ID) {
        return processObjectRequest(action, state, 'SessionNotesEntries');
    }

    //this should only happen once
    if (action.type === Constants.GET_SESSION_NOTES_ENTRY_BY_ID_RESPONSE) {
        return processObjectResponse(action, state, 'SessionNotesEntry', 'SessionNotesEntries', true);
    }

    if (action.type === Constants.GET_ALL_SESSION_NOTES_ENTRIES_BY_CLIENT) {
        return processQueryRequest(action, state, 'SessionNotesEntries');
    }

    if (action.type === Constants.GET_ALL_SESSION_NOTES_ENTRIES_BY_CLIENT_RESPONSE) {
        return processQueryResponse(action, state, 'SessionNotesEntry', 'SessionNotesEntries');
    }
    

    if (action.type === Constants.GET_REQUIRED_SIGNATURE_STATS_RESPONSE) {
        const { sessionNote = 0, BIP = 0 } = action.response;
        return {
            ...state,
            stats: {
                ...state.stats,
                signDocument: {
                    ...state.stats.signDocument,
                    sessionNote,
                    BIP,
                }
            }
        };
    }

    if (action.type === Constants.GET_REQUIRED_TECHNICIAN_SIGNATURE_STATS_RESPONSE) {
        const { sessionNote = 0 } = action.response;
        return {
            ...state,
            stats: {
                ...state.stats,
                signTechnicianDocument: {
                    ...state.stats.signTechnicianDocument,
                    sessionNote,
                },
            },
        };
    }

    if (action.type === Constants.GET_CLIENT_SESSION) {
        return processObjectRequest(action, state, 'ClientSessions');
    }

    if (action.type === Constants.GET_CLIENT_SESSION_RESPONSE) {
        return processObjectResponse(action, state, 'ClientSession', 'ClientSessions');
    }

    if (action.type === Constants.GET_ALL_MISSING_APPOINTMENTS_BY_CLIENT) {
        return processQueryRequest(action, state, 'Appointments');
    }

    if (action.type === Constants.GET_ALL_MISSING_APPOINTMENTS_BY_CLIENT_RESPONSE) {

        const id = action.request.url.match(/[^/]*$/)[0];
        const query = Qs.stringify(action.request.query);
        state = processQueryResponse(action, state, 'Appointment', 'Appointments');
        const networkUpdates = state.collections.Appointments.networkStatusByQuery[query]

        if (id === 'missing-appointments' && !networkUpdates.err && !networkUpdates.error) {
            state = {
                ...state,
                currentMissingAppointments: {
                    ...action.response,
                    data: action.response.data.map(item => item.id),
                },
            }
        }

        return state;
    }

    if (action.type === Constants.POST_REQUEST_APPROVAL_BY_CLIENT) {
        return processPostObject(action, state, 'Appointment');
    }

    if (action.type === Constants.POST_REQUEST_APPROVAL_BY_CLIENT_RESPONSE) {
        return processPostObjectResponse(action, state, 'Appointment');
    }

    if (action.type === Constants.GET_LESSON_PLANS_BY_ID) {
        return processObjectRequest(action, state, 'LessonPlans');
    }

    if (action.type === Constants.GET_LESSON_PLANS_BY_ID_RESPONSE) {
        return processObjectResponse(action, state, 'LessonPlan', 'LessonPlans');
    }

    if (action.type === Constants.UPDATE_RBX_DATA_SHEET) {
        return processLocalChange(state, 'PPBxesGoalsLessonsDataSheet', action.data, !!action.remove);
    }

    if (action.type === Constants.SAVE_RBX_DATA_SHEET) {
        return processSaveObject(action, state, 'PPBxesGoalsLessonsDataSheets');
    }

    if (action.type === Constants.SAVE_RBX_DATA_SHEET_RESPONSE) {
        return processSaveObjectResponse(action, state, 'PPBxesGoalsLessonsDataSheet');
    }

    if (action.type === Constants.SAVE_PPROBLEM_BXES_DATA_SHEET) {
        return processSaveObject(action, state, 'PProblemBxesDataSheets');
    }

    if (action.type === Constants.SAVE_PPROBLEM_BXES_DATA_SHEET_RESPONSE) {
        return processSaveObjectResponse(action, state, 'PProblemBxesDataSheet');
    }

    return state;
};

export { reducer, initialState };
