

import FluxConstant from 'flux-constant';

export default FluxConstant.set([

    'INITIAL_DOWNLOAD_COMPLETE',
    'RESTORE_FROM_INDEXED_DB',
    'UPDATE_DATA_SHEET_STATE',

    'SHOW_MTS_CONFIRM_INTERVAL_MODAL',
    'HIDE_MTS_CONFIRM_INTERVAL_MODAL',

    'SHOW_PIR_CONFIRM_INTERVAL_MODAL',
    'HIDE_PIR_CONFIRM_INTERVAL_MODAL',

    'SHOW_PIR_ABC_STRUCTURED_CONFIRM_INTERVAL_MODAL',
    'HIDE_PIR_ABC_STRUCTURED_CONFIRM_INTERVAL_MODAL',
    'SHOW_PIR_ABC_NARRATIVE_CONFIRM_INTERVAL_MODAL',
    'HIDE_PIR_ABC_NARRATIVE_CONFIRM_INTERVAL_MODAL',

    'SHOW_PIR_ABC_STRUCTURED_MODIFY_TRIAL_MODAL',
    'HIDE_PIR_ABC_STRUCTURED_MODIFY_TRIAL_MODAL',
    'SHOW_PIR_ABC_NARRATIVE_MODIFY_TRIAL_MODAL',
    'HIDE_PIR_ABC_NARRATIVE_MODIFY_TRIAL_MODAL',

    'SHOW_CONFIRM_INTERVAL_MODAL',
    'HIDE_CONFIRM_INTERVAL_MODAL',

    'SHOW_DURATION_MODIFY_TRIAL_MODAL',
    'HIDE_DURATION_MODIFY_TRIAL_MODAL',

    'SHOW_EVENT_RECORDING_ABC_STRUCTURED_MODIFY_TRIAL_MODAL',
    'HIDE_EVENT_RECORDING_ABC_STRUCTURED_MODIFY_TRIAL_MODAL',
    'SHOW_EVENT_RECORDING_ABC_NARRATIVE_MODIFY_TRIAL_MODAL',
    'HIDE_EVENT_RECORDING_ABC_NARRATIVE_MODIFY_TRIAL_MODAL',

    'SHOW_DATA_SHEET_SETTINGS',
    'HIDE_DATA_SHEET_SETTINGS',
    'SHOW_START_SESSION',
    'HIDE_START_SESSION',
    'RESET_ALL_DETAILS',
    'SAVE_DATA_SHEET',
    'SAVE_DATA_SHEET_RESPONSE',
    'ADD_DATA_SHEET',
    'ADD_DATA_SHEET_RESPONSE',
    'GET_PPROBLEM_BX',
    'GET_PPROBLEM_BX_RESPONSE',
    'GET_DATA_SHEETS_BY_CLIENT',
    'GET_DATA_SHEETS_BY_CLIENT_RESPONSE',
    'GET_PLESSON',
    'GET_PLESSON_RESPONSE',
    'GET_CLIENT_DETAILS',
    'GET_CLIENT_DETAILS_RESPONSE',
    'GET_PTARGETS',
    'GET_PTARGETS_RESPONSE',
    'SAVE_PLESSON_BX',
    'SAVE_PLESSON_BX_RESPONSE',
    'SAVE_PPROBLEM_BX',
    'SAVE_PPROBLEM_BX_RESPONSE',
    'PATCH_PTARGETS',
    'PATCH_PTARGETS_RESPONSE',
    'PATCH_PTARGETS_BY_PLESSON',
    'PATCH_PTARGETS_BY_PLESSON_RESPONSE',
    'RESET_PPROBLEM_BX',
    'DELETE',
    'GET_PSDR',
    'GET_PSDR_RESPONSE',
    'GET_MAINTENANCE_PSDR',
    'GET_MAINTENANCE_PSDR_RESPONSE',
    'DELETE_RESPONSE',
    'POST_PPROBLEM_BXES_DATA_SHEET',
    'POST_PPROBLEM_BXES_DATA_SHEET_RESPONSE',
    'GET_ALL_PLESSONS_BY_CLIENT',
    'GET_ALL_PLESSONS_BY_CLIENT_RESPONSE',
    'GET_ALL_PPROBLEM_BXES_BY_CLIENT',
    'GET_ALL_PPROBLEM_BXES_BY_CLIENT_RESPONSE',
    'GET_ALL_DATA_SHEETS_BY_CLIENT',
    'GET_ALL_DATA_SHEETS_BY_CLIENT_RESPONSE',
    'GET_ALL_OACCOUNTS_BY_CLIENT',
    'GET_ALL_OACCOUNTS_BY_CLIENT_RESPONSE',
    'UPDATE_DATA_SHEET',
    'UPDATE_DATA_SHEETS_PTARGET',
    'DELETE_DATA_SHEET',
    'DELETE_DATA_SHEET_RESPONSE',
    'SAVE_DATA_SHEET',
    'SAVE_DATA_SHEET_RESPONSE',
    'UPDATE_PPROBLEM_BXES_DATA_SHEET',
    'SAVE_PPROBLEM_BXES_DATA_SHEET',
    'SAVE_PPROBLEM_BXES_DATA_SHEET_RESPONSE',

    'UPDATE_TRIAL',
    'DELETE_TRIAL',
    'DELETE_TRIAL_RESPONSE',
    'SAVE_TRIAL',
    'SAVE_TRIAL_RESPONSE',

    'UPDATE_PTARGET',
    'DELETE_PTARGET',
    'DELETE_PTARGET_RESPONSE',
    'SAVE_PTARGET',
    'SAVE_PTARGET_RESPONSE',

    'GET_CURRENT_SESSION',
    'GET_CURRENT_SESSION_RESPONSE',
    'GET_LATEST_SESSION',
    'GET_LATEST_SESSION_RESPONSE',

    'UPDATE_CLIENT_SESSION',
    'SAVE_CLIENT_SESSION',
    'SAVE_CLIENT_SESSION_RESPONSE',

    'GET_ALL_CLIENTS',
    'GET_ALL_CLIENTS_RESPONSE',
    'GET_CLIENT_SESSION_BY_CLIENT',
    'GET_CLIENT_SESSION_BY_CLIENT_RESPONSE',
    'GET_ALL_CLIENT_SESSIONS_BY_CLIENT',
    'GET_ALL_CLIENT_SESSIONS_BY_CLIENT_RESPONSE',
    'GET_ALL_CLIENT_SESSIONS_STATS_BY_CLIENT',
    'GET_ALL_CLIENT_SESSIONS_STATS_BY_CLIENT_RESPONSE',
    'GET_ALL_NOTES_BY_CLIENT',
    'GET_ALL_NOTES_BY_CLIENT_RESPONSE',
    'UPDATE_NOTE',
    'POST_NOTE',
    'POST_NOTE_RESPONSE',
    'SAVE_NOTE',
    'SAVE_NOTE_RESPONSE',
    'ACKNOWLEDGE_NOTE',
    'ACKNOWLEDGE_NOTE_RESPONSE',
    'UPDATE_CLIENTS_OACCOUNT',
    'UPDATE_CLIENTS_OACCOUNT_RESPONSE',
    'PATCH_CLIENTS_OACCOUNTS_SETTINGS',
    'PATCH_CLIENTS_OACCOUNTS_SETTINGS_RESPONSE',

    'GET_DATASHEETS_SEQUENCES',
    'GET_DATASHEETS_SEQUENCES_RESPONSE',
    'UPDATE_DATASHEETS_SEQUENCE',

    'GET_ALL_CLIENT_ACTIVITY_BY_CLIENT',
    'GET_ALL_CLIENT_ACTIVITY_BY_CLIENT_RESPONSE',

    'UPDATE_CLIENTS_OACCOUNT_PERMISSIONS',

    'GET_SESSION_NOTES_BY_QUERY',
    'GET_SESSION_NOTES_BY_QUERY_RESPONSE',
    'UPDATE_CURRENT_SESSION_NOTES',

    'UPDATE_SESSION_NOTES',

    'GET_APPOINTMENTS_BY_QUERY',
    'GET_APPOINTMENTS_BY_QUERY_RESPONSE',
    'UPDATE_CURRENT_CLIENT_APPOINTMENTS',

    'UPDATE_SESSION_NOTES_ENTRY',
    'SAVE_SESSION_NOTES_ENTRY',
    'SAVE_SESSION_NOTES_ENTRY_RESPONSE',
    'DELETE_SESSION_NOTES_ENTRY',
    'DELETE_SESSION_NOTES_ENTRY_RESPONSE',
    'GET_SESSION_NOTES_ENTRY_BY_SESSION',
    'GET_SESSION_NOTES_ENTRY_BY_SESSION_RESPONSE',
    'GET_SESSION_NOTES_ENTRY_BY_ID',
    'GET_SESSION_NOTES_ENTRY_BY_ID_RESPONSE',
    'GET_ALL_SESSION_NOTES_ENTRIES_BY_CLIENT',
    'GET_ALL_SESSION_NOTES_ENTRIES_BY_CLIENT_RESPONSE',
    'SESSION_NOTES_ENTRIES_BULK_SIGNING',
    'SESSION_NOTES_ENTRIES_BULK_SIGNING_RESPONSE',
    'GET_REQUIRED_SIGNATURE_STATS',
    'GET_REQUIRED_SIGNATURE_STATS_RESPONSE',
    'UPDATE_SESSION_NOTES_ENTRIES_SIGNATURE',
    'GET_REQUIRED_TECHNICIAN_SIGNATURE_STATS',
    'GET_REQUIRED_TECHNICIAN_SIGNATURE_STATS_RESPONSE',

    'P_PROBLEM_BXES_BULK_SIGNING',
    'P_PROBLEM_BXES_BULK_SIGNING_RESPONSE',
    'UPDATE_P_PROBLEM_BXES_SIGNATURE',
    'UPDATE_P_PROBLEM_BXES_SIGNATURE_RESPONSE',

    'GET_CLIENT_SESSION',
    'GET_CLIENT_SESSION_RESPONSE',

    'GET_CLIENTS_AUTHORIZATIONS_BY_CLIENT',
    'GET_CLIENTS_AUTHORIZATIONS_BY_CLIENT_RESPONSE',

    'GET_ALL_MISSING_APPOINTMENTS_BY_CLIENT',
    'GET_ALL_MISSING_APPOINTMENTS_BY_CLIENT_RESPONSE',
    'POST_REQUEST_APPROVAL_BY_CLIENT',
    'POST_REQUEST_APPROVAL_BY_CLIENT_RESPONSE',

    'GET_ACCOUNT_SIGNIFICANT_EVENTS',
    'GET_ACCOUNT_SIGNIFICANT_EVENTS_RESPONSE',
    'GET_ADMIN_SIGNIFICANT_EVENTS',
    'GET_ADMIN_SIGNIFICANT_EVENTS_RESPONSE',

    'GET_LESSON_PLANS_BY_CLIENT',
    'GET_LESSON_PLANS_BY_CLIENT_RESPONSE',
    'GET_LESSON_PLANS_BY_ID',
    'GET_LESSON_PLANS_BY_ID_RESPONSE',
    
    'UPDATE_LESSON_PLANS_CLIENTS_OACCOUNT',
    'PATCH_LESSON_PLANS_CLIENTS_OACCOUNTS_SETTINGS',
    'PATCH_LESSON_PLANS_CLIENTS_OACCOUNTS_SETTINGS_RESPONSE',

    'UPDATE_RBX_DATA_SHEET',
    'SAVE_RBX_DATA_SHEET',
    'SAVE_RBX_DATA_SHEET_RESPONSE',

    'UPDATE_RESUME_INTERVAL',
    'RESET_RESUME_INTERVAL',
]);
